<template>
    <!---------------------------------------------- Horizontal Widget ---------------------------------------------->
    <div class="d-flex align-items-center justify-content-center flex-column vh-100" v-if="!$route.query.bt">
        <div class="bg-white position-relative w-100" style="max-width: 508px;min-width: 259px;border-radius:8px">
            <v-lazy-image style="margin:3px;" width="100" height="20" src="/assets/images/logo.svg" :alt="$t('carouselWatchlist.inText1')" :title="$t('tradeInfo.infoText1')" />
           <!--  <div>
                <a href="javascript:void(0)" class="arrow-img left" @click="prev()">
                    <v-lazy-image src="/assets/images/left-arrow.png" alt="Logo" title="left-arrow" />
                </a>
                <a href="javascript:void(0)" class="arrow-img right" @click="next()">
                    <v-lazy-image src="/assets/images/right-arrow.png" alt="Logo" title="right-arrow" />
                </a>
            </div> -->
            <div class="row m-2" v-if="Object.keys(perItem).length">
                <div class="col m-0 p-0 position-relative" style="max-width: 250px;">
                    <!-- <router-link class="d-flex justify-content-center" :to="{ path:'/', query:{'ref':$route.query.ref, 'utm_source':$route.query.utm_source, 'utm_campaign':$route.query.utm_campaign, 'utm_medium':$route.query.utm_medium} }" target="_blank">
                        <v-lazy-image width="180" height="40" src="/assets/images/logo.svg" alt="Logo" title="ZulluTrade" />
                    </router-link> -->
                    <select v-model="charSelected" class="form-select py-0 px-1 d-block" style="font-size:12px;width:120px">
                        <option v-for="item,key in chartTypes" :key="key" :value="key">{{item.name}}</option>
                    </select>
                    <!-- <div class="chartElement" id="myChart"></div> -->
                    <div id="traderprofitChart" class="profit" :style="($route.query.utm_campaign == 'DynamicWidget250x600') ? 'width: 250px; height: 170px; display: flex;' : 'width: 250px; height: 300px; display: flex;'" v-show="Object.keys(store.widgetChartData).length && store.widgetChartData.series?.length && store.widgetChartData.series[0].data.length"></div>
                    <!-- <div id="traderprofitChart" class="profit" style="width: 250px; height: 300px; display: flex;" v-show="Object.keys(store.widgetChartData).length && store.widgetChartData.series?.length && store.widgetChartData.series[0].data.length"></div> -->
                    <Nodata class="widgetChartData" v-if="!(Object.keys(store.widgetChartData).length && store.widgetChartData.series?.length && store.widgetChartData.series[0].data.length)"></Nodata>
                    <Loader :classname="'innerLoader'" v-if="chartLoader"></Loader>
                    <p class="text-center f-14 mt-2">{{$t('tradeInfo.infoText2')}}</p>
                </div>
                <div class="col m-0 p-0" style="max-width: 250px;" v-if="perItem.stats.profile">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-start">
                                <span class="m-2">
                                    <v-lazy-image
                                        width="30"
                                        height="30"
                                        class="rounded-circle"
                                        :src="static_vars.imagesURL+'webservices/Image.ashx?type=user&size=XL&id='+perItem.stats.profile.zuluAccountId+'&ignore=false'"
                                        :alt="perItem.stats.profile.name"
                                        :title="perItem.stats.profile.name"
                                    />
                                </span>
                                <h1 class="mb-0" style="font-size: 16px;line-height: 1.4;">{{ perItem.stats.profile.name }}</h1>
                            </div>
                            <div class="title">
                                <div class="element mb-1">
                                    <div class="element w-100 d-flex justify-content-between align-items-center">
                                        <!-- v-if="perItem.stats.overallStats.followers" -->
                                        <span class="moregray f-12 mb-0 me-1 gray">{{$t('tradeInfo.infoText3')}}</span>
                                        <strong class="d-block" style="font-size: 16px;color: var(--secondary);font-weight: bold;">
                                            {{ perItem.stats.profile.baseCurrencySymbol }}{{ loc(Math.round(perItem.stats.overallStats.amountFollowing || 0)) }}
                                        </strong>
                                    </div>
                                    <div class="element w-100 d-flex justify-content-between align-items-center" v-if="perItem.stats.overallStats && perItem.stats.overallStats.includedInWatchlist">
                                        <span class="moregray f-12 mb-0 me-1 gray">{{$t('tradeInfo.infoText4')}}</span>
                                        <strong class="text-end m-0 d-block green" style="font-size: 16px;font-weight: bold;">
                                            {{ loc(Math.round(timeFrame.annualizedRorBasedRoi || 0)) }}%
                                        </strong>
                                    </div>
                                </div>
                                <div class="element f-12">
                                    <p class="d-flex justify-content-between f-12">
                                        <span class="gray">{{$t('tradeInfo.infoText5')}} </span> <strong class="green">{{perItem.stats.profile.baseCurrencySymbol}}{{ loc(timeFrame2.totalProfitMoney || 0) }}</strong>
                                    </p>
                                    <p class="d-flex justify-content-between f-12">
                                        <span class="gray">{{$t('tradeInfo.infoText6')}} </span> <strong>{{ loc(perItem.stats.overallStats.weeks || 0) }}</strong>
                                    </p>
                                    <p class="d-flex justify-content-between f-12">
                                        <span class="gray">{{$t('tradeInfo.infoText7')}} </span> <strong>{{timeFrame2.profitPercentage?loc(timeFrame2.profitPercentage.toFixed(2) || 0):0}}%</strong>
                                    </p>
                                    <p class="d-flex justify-content-between f-12">
                                        <span class="gray">{{$t('tradeInfo.infoText8')}} </span> <strong>-{{loc(timeFrame2.overallDrawDownMoneyPercent || 0)}}%</strong>
                                    </p>
                                    <p class="d-flex justify-content-between f-12">
                                        <span class="gray">{{$t('tradeInfo.infoText9')}} </span> <strong>{{loc(timeFrame2.winTradesCount || 0)}} ({{loc(timeFrame2.winTrades || 0)}}%)</strong>
                                    </p>
                                    <p class="d-flex justify-content-between f-12">
                                        <span class="gray">{{$t('tradeInfo.infoText10')}} </span> <strong>{{loc(timeFrame2.maxOpenTrades || 0)}}</strong>
                                    </p>
                                    <p class="d-flex justify-content-between f-12">
                                        <span class="gray">{{$t('tradeInfo.infoText11')}} </span> <strong>{{ loc(perItem.stats.overallStats.followers || 0) }}</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="loginRegister mt-2">
                        <li class="d-flex justify-content-around">
                            <router-link :to="{ path:'/trader/'+userID+'/trading', query:{'ref':$route.query.ref, 'utm_source':$route.query.utm_source, 'utm_campaign':$route.query.utm_campaign, 'utm_medium':$route.query.utm_medium,'utm_content':'followbutton','t':10000 ,'m':1} }" class="" style="border-radius: 15rem; padding: 4px 1rem;width:50%; text-align:center; background-color: #ff7200; border: 1px solid #ff7200; color: #ffffff;font-size: 14px;margin-right:8px;" target="_blank">
                                {{$t('tradeInfo.infoText12')}}
                            </router-link>
                            <router-link :to="{ path:'/login', query:{'ref':$route.query.ref, 'utm_source':$route.query.utm_source, 'utm_campaign':$route.query.utm_campaign, 'utm_medium':$route.query.utm_medium,'utm_content':'loginbutton'} }" class="" style="border-radius: 15rem; padding: 4px 1rem;  background-color: #ff7200; border: 1px solid #ff7200; color: #ffffff;font-size: 14px; width:50%; text-align:center;" target="_blank">
                                {{$t('tradeInfo.infoText13')}}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!---------------------------------------------- Vertically Widget ---------------------------------------------->
    <div class="d-flex align-items-center justify-content-center flex-column vh-100 p-2" v-if="$route.query.bt">
        <div class="bg-white position-relative w-100">
            <div  v-if="Object.keys(perItem).length">
                <div class="m-0 p-0 position-relative" style="max-width: 250px;">
                    <v-lazy-image style="margin:3px;" width="100" height="20" src="/assets/images/logo.svg" alt="Logo" title="ZulluTrade" />
                    <select v-model="charSelected" class="form-select py-0 px-1 d-block" style="font-size:12px;width:120px">
                        <option v-for="item,key in chartTypes" :key="key" :value="key">{{item.name}}</option>
                    </select>
                    <!-- <div class="chartElement" id="myChart"></div> -->
                    <div id="traderprofitChart" class="profit" style="width: 250px; height: 120px; display: flex;" v-show="Object.keys(store.widgetChartData).length && store.widgetChartData.series?.length && store.widgetChartData.series[0].data.length"></div>
                    <Nodata  class="widgetChartData" v-if="!(Object.keys(store.widgetChartData).length && store.widgetChartData.series?.length && store.widgetChartData.series[0].data.length)"></Nodata>
                    <Loader :classname="'innerLoader'" v-if="chartLoader"></Loader>
                    <p class="text-center f-14 mt-2">{{$t('tradeInfo.infoText2')}}</p>
                </div>
                <div class="m-0 p-0" style="max-width: 250px;" v-if="perItem.stats.profile">
                    <div class="card">
                        <div class="card-body">
                            <div class="d-flex justify-content-start align-items-center">
                                <span class="me-2">
                                    <v-lazy-image
                                        width="30"
                                        height="30"
                                        class="rounded-circle"
                                        :src="static_vars.imagesURL+'webservices/Image.ashx?type=user&size=XL&id='+perItem.stats.profile.zuluAccountId+'&ignore=false'"
                                        :alt="perItem.stats.profile.name"
                                        :title="perItem.stats.profile.name"
                                    />
                                </span>
                                <h1 class="mb-0" style="line-height: 1.4; font-size: 16px;">{{ perItem.stats.profile.name }}</h1>
                            </div>
                            <div class="title">
                                <div class="element mb-1">
                                    <div class="element w-100 d-flex justify-content-between align-items-center">
                                        <!-- v-if="perItem.stats.overallStats.followers" -->
                                        <span class="moregray f-12 mb-0 me-1 gray">{{$t('tradeInfo.infoText3')}}</span>
                                        <strong class="d-block" style="font-size: 16px;color: var(--secondary);font-weight: bold;">
                                            {{ perItem.stats.profile.baseCurrencySymbol }}{{ loc(Math.round(perItem.stats.overallStats.amountFollowing || 0)) }}
                                        </strong>
                                    </div>
                                    <div class="element w-100 d-flex justify-content-between align-items-center" v-if="perItem.stats.overallStats && perItem.stats.overallStats.includedInWatchlist">
                                        <span class="moregray f-12 mb-0 me-1 gray">{{$t('tradeInfo.infoText4')}}</span>
                                        <strong class="text-end m-0 d-block green" style="font-size: 16px;font-weight: bold;">
                                            {{ loc(Math.round(timeFrame.annualizedRorBasedRoi || 0)) }}%
                                        </strong>
                                    </div>
                                </div>
                                <div class="element f-12">
                                    <p class="d-flex justify-content-between f-12">
                                        <span class="gray">{{$t('tradeInfo.infoText5')}} </span> <strong class="green">{{perItem.stats.profile.baseCurrencySymbol}}{{ loc(timeFrame2.totalProfitMoney || 0) }}</strong>
                                    </p>
                                    <p class="d-flex justify-content-between f-12">
                                        <span class="gray">{{$t('tradeInfo.infoText6')}} </span> <strong>{{ loc(perItem.stats.overallStats.weeks || 0) }}</strong>
                                    </p>
                                    <p class="d-flex justify-content-between f-12">
                                        <span class="gray">{{$t('tradeInfo.infoText7')}} </span> <strong>{{timeFrame2.profitPercentage?loc(timeFrame2.profitPercentage.toFixed(2) || 0):0}}%</strong>
                                    </p>
                                    <p class="d-flex justify-content-between f-12">
                                        <span class="gray">{{$t('tradeInfo.infoText8')}} </span> <strong>-{{loc(timeFrame2.overallDrawDownMoneyPercent || 0)}}%</strong>
                                    </p>
                                    <p class="d-flex justify-content-between f-12">
                                        <span class="gray">{{$t('tradeInfo.infoText9')}} </span> <strong>{{loc(timeFrame2.winTradesCount || 0)}} ({{loc(timeFrame2.winTrades || 0)}}%)</strong>
                                    </p>
                                    <p class="d-flex justify-content-between f-12">
                                        <span class="gray">{{$t('tradeInfo.infoText10')}} </span> <strong>{{loc(timeFrame2.maxOpenTrades || 0)}}</strong>
                                    </p>
                                    <p class="d-flex justify-content-between f-12">
                                        <span class="gray">{{$t('tradeInfo.infoText11')}}: </span> <strong>{{ loc(perItem.stats.overallStats.followers || 0) }}</strong>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ul class="loginRegister mt-2">
                        <li class="d-flex justify-content-around">
                            <router-link :to="{ path:'/trader/'+userID+'/trading', query:{'ref':$route.query.ref, 'utm_source':$route.query.utm_source, 'utm_campaign':$route.query.utm_campaign, 'utm_medium':$route.query.utm_medium,'utm_content':'followbutton','t':10000 ,'m':1} }" class="" style="border-radius: 15rem; padding: 4px 1rem;width:50%; text-align:center; background-color: #ff7200; border: 1px solid #ff7200; color: #ffffff;font-size: 14px;margin-right:8px;" target="_blank">
                                {{$t('tradeInfo.infoText12')}}
                            </router-link>
                            <router-link :to="{ path:'/login', query:{'ref':$route.query.ref, 'utm_source':$route.query.utm_source, 'utm_campaign':$route.query.utm_campaign, 'utm_medium':$route.query.utm_medium,'utm_content':'loginbutton'} }" class="" style="border-radius: 15rem; padding: 4px 1rem;  background-color: #ff7200; border: 1px solid #ff7200; color: #ffffff;font-size: 14px; width:50%; text-align:center;" target="_blank">
                                {{$t('tradeInfo.infoText13')}}
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    </template>
    <script>
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import * as am5percent from "@amcharts/amcharts5/percent";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            return { store : myStore() };
        },
        data() {
            return {
                details:{},
                userID:'',
                traderList:{},
                traderIdList:[],
                chartLoader: false,
                chartTypes:{
                    1:{ name:'Profit', params: { 'type': 'TOTAL_PROFIT_MONEY', 'timespan': 'DAILY', 'timeframe': '10000'}},
                    2:{ name:'Monthly Profit', params: { 'type': 'TOTAL_PROFIT_MONEY', 'timespan': 'DAILY', 'timeframe': '30'}},
                    3:{ name:'Pairs', params: { 'type': 'CURRENCY_COUNT', 'timespan': 'STATS', 'timeframe': '10000'}}
                },
                charSelected: 1,
                chartData:{}
            };
        },
        watch:{
            userID(id){
                this.calltraderDetail(id);
                // this.getChartData(id);
            },
            charSelected(){
                this.getChartData(this.userID);
            }
        },
        computed:{
            perItem(){
                // return (((this.store.topTrdaersList || [])[0] || {}).result || [])[0]
                return this.traderList[this.userID] || {}
            },
            timeFrame(){
                try{
                    return this.perItem.stats.timeframeStats[Math.max(...Object.keys(this.perItem.stats.timeframeStats))]
                }catch(e){ return {} }
            },
            timeFrame2(){
                let d = ((this.details[this.userID] || {}).stats || {}).timeframeStats
                if(d && Object.keys(d).length > 0){
                    return d[Math.max(...Object.keys(d))]
                }else{
                    return {}
                }
            }
        },
        methods: {
            prev(){
                if(!isNaN(this.$route.query.size) && this.$route.query.size > 1){
                    let list = this.traderIdList.slice(0, this.$route.query.size)
                    let i = list.indexOf(this.userID)
                    if((i-1) >= 0){
                        this.userID = list[i-1]
                    }else{
                        this.userID = list[list.length-1]
                    }
                }
            },
            next(){
                if(!isNaN(this.$route.query.size) && this.$route.query.size > 1){
                    let list = this.traderIdList.slice(0, this.$route.query.size)
                    let i = list.indexOf(this.userID)
                    if((i+1) <= (list.length-1)){
                        this.userID = list[i+1]
                    }else{
                        this.userID = list[0]
                    }
                }
            },
            loc(v){
                let num = new Number(v)
                return num.toLocaleString();
            },
            getChartData(id){
                this.chartLoader = true
                if(this.chartData[id] && this.chartData[id][this.charSelected]){
                    this.chartLoader = false
                    if(this.charSelected == 3){
                        this.drawPieChart()
                    }else{
                        this.drawChart()
                    }
                }else{
                    this.store.allChartData(this.chartTypes[this.charSelected].params,true,id).then(response =>{
                        if(response.series && response.series[0]){
                            if(!this.chartData[id]){ this.chartData[id] = { 1:null, 2:null, 3:null }; }
                            this.chartData[id][this.charSelected] = response.series[0]
                        }
                        this.chartLoader = false
                        if(this.charSelected == 3){
                            this.drawPieChart()
                        }else{
                            this.drawChart()
                        }
                    })
                }
            },
            getTopTradersList() {
                this.store.callTopTradersList({}, true).then(res => {
                    res[0].result.forEach(item=>{
                        this.traderList[item.trader.providerId] = item.trader
                        this.traderIdList.push(item.trader.providerId)
                    })
                    this.userID = this.traderIdList[0]
                })
            },
            calltraderDetail(id){
                if(this.details[id]){ return }
                this.store.calltraderDetail({}, true,id,this).then((res) => {
                    if(res.trader){
                        this.traderList[id] = res.trader
                        this.details[id] = res.trader
                        this.getChartData(id)
                    }
                });
            },
            drawChart(){
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === "traderprofitChart");
                d?.dispose();
                let root = am5.Root.new("traderprofitChart");
    
                root.interfaceColors.set("grid", am5.color(0xffffff));
    
                root.setThemes([am5themes_Animated.new(root)]);
    
                root.dateFormatter.setAll({
                    dateFormat: "dd MMM yyyy",
                    dateFields: ["valueX"],
                });
                root.numberFormatter.setAll({
                    numberFormat: "#.##",
                    smallNumberThreshold: 0.001,
                });
                // Create drawprofitChart
                var chart = root.container.children.push(
                    am5xy.XYChart.new(root, { focusable: true, paddingRight: 0, paddingLeft: 0, paddingBottom: 0 })
                );
                var xAxis = chart.xAxes.push(am5xy.DateAxis.new(root, {
                        maxDeviation: 0.5,
                        visible: false,
                        baseInterval: {
                            timeUnit: "day",
                            count: 1
                        },
                        renderer: am5xy.AxisRendererX.new(root, {
                        pan:"zoom"
                    }),
                    // tooltip: am5.Tooltip.new(root, {})
                }));
    
                let yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        visible: false,
                        strictMinMax: true,
                        renderer: am5xy.AxisRendererY.new(root, {}),
                    })
                );
                let yRenderer = yAxis.get("renderer");
                yRenderer.grid.template.setAll({ disabled : true });
                let chartData = this.chartDataProfit()
                if (chartData.length > 1) {
                    chart.get("colors").set("colors", [am5.color(0xffdfc3), am5.color(0xff7200)]);
                } else {
                    chart.get("colors").set("colors", [am5.color(0xff7200)]);
                }
                chartData.forEach((key) => {
                    // Add series
                    var series = chart.series.push(
                        am5xy.SmoothedXLineSeries.new(root, {
                            // legendLabelText: "Profit (" + this.perItem.stats.profile.baseCurrencyName + ")",
                            minBulletDistance: 1,
                            xAxis: xAxis,
                            yAxis: yAxis,
                            valueYField: "y",
                            valueXField: "x",
                            seriesTooltipTarget: "bullet",
                            tooltip: am5.Tooltip.new(root, {
                                getFillFromSprite: false,
                                getStrokeFromSprite: true,
                                autoTextColor: true,
                                getLabelFillFromSprite: true,
                                pointerOrientation: "horizontal",
                                scale:0.7,
                                /*stroke: am5.color(0xff5566),
                                strokeOpacity: 0.8,*/
                                fill: am5.color(0xffffff),
                                fillOpacity: 0,
                                labelText: `${key.text?(key.text+'\n'):''}[bold]${key.name} (${this.perItem.stats.profile.baseCurrencyName}):[/] {valueY}\n[bold]Date:[/] {valueX}`,
                            }),
                        })
                    );
                    series.fills.template.setAll({ visible: true });
    
                    series.fills.template.set(
                        "fillGradient",
                        am5.LinearGradient.new(root, {
                            stops: [ { opacity: 0.5 }, { opacity: 0.2 }, ],
                        })
                    );
                    var data = key.data;
                    series.data.setAll(data);
                    series.appear(100);
                });
    
                // Add cursor
                let cursor = chart.set(
                    "cursor",
                    am5xy.XYCursor.new(root, {
                        // xAxis: xAxis,
                        behavior: "zoomX",
                    })
                );
                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);
    
                //chart.zoomOutButton.set("forceHidden", true);
    
                /*xAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#666666")),
                    fontSize: 10,
                });
                yAxis.get("renderer").labels.template.setAll({
                    fill: root.interfaceColors.set("fill", am5.color("#ffa35a")),
                    fontSize: 12,
                });
                var label2 = am5.Label.new(root, {
                    text: "[#666] Date [/]",
                    x: am5.p50,
                    centerY: am5.p50,
                    fontSize: 12,
                });
                xAxis.children.push(label2);*/
                chart.appear(1000, 100);
            },
            drawPieChart(){
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === 'traderprofitChart');
                d?.dispose();
                var root = am5.Root.new("traderprofitChart");
                root.setThemes([
                    am5themes_Animated.new(root),
                    am5themes_Responsive.new(root)
                ]);
    
                // Create chart
                var chart = root.container.children.push(am5percent.PieChart.new(root, {
                    layout: root.verticalLayout
                }));
    
                var series = chart.series.push(am5percent.PieSeries.new(root, {
                    valueField: "y",
                    categoryField: "x",
                }));
                series.get("colors").set("colors", [
                    am5.color(0x4572a7),
                    am5.color(0xCBCBCB),
                    am5.color(0x69cd4b),
                    am5.color(0xff6f34),
                    am5.color(0xf6f819),
                    am5.color(0x7dfe8b),
                    am5.color(0xffaf6e),
                    am5.color(0xfff263),
                    am5.color(0xff7200),
                ]);
                series.data.setAll(this.chartData[this.userID][this.charSelected].data);
                series.slices.template.set("tooltipText", "{x}: {y}");
                series.slices.template.set("toggleKey", "none");
                series.labels.template.setAll({
                    text: "{x}",
                    radius: 10,
                    fontSize: 12,
                    fontWeight: "600",
                });
                series.appear(1000, 100);
            },
            chartDataProfit(){
                if(this.charSelected != 2){
                    let chartData = this.chartData[this.userID][this.charSelected]
                    var index = chartData.endDateOfImportedTradeHistory?chartData.data.findIndex(ind => ind.x > chartData.endDateOfImportedTradeHistory):-1;
                    var finaldata = [];
                    if(index != -1){
                        finaldata.push({ data: chartData.data.slice(0, index), name: "Imported history Profit", text:'Performance using Imported history' });
                        finaldata.push({ data: chartData.data.slice(index - 1), name: "Live Profit", text:'Performance using Live account' });
                    }else{
                        finaldata.push({ data: chartData.data, name: "Live Profit" });
                    }
                    return finaldata;
                }else{
                    return [{data : this.chartData[this.userID][this.charSelected].data, name : "Live Profit"}]
                }
            },
    
        },
        created() {
            //this.getTopTradersList()
            if(this.$route.params.id){
                 this.userID = this.$route.params.id
            }
        }
    }
    </script>
    <style scoped>
        p{ margin-bottom: 0.5rem !important; }
        .gray{ color: #959595; font-size: 12px; font-weight: 600; }
        .arrow-img{
            top: calc(50% - 29px);
            z-index: 10;
            position: absolute;
        }
        .arrow-img a {
            cursor: pointer;
            background-color:transparent;
        }
        a.arrow-img.left { left: 0; }
        a.arrow-img.right { right: 0; }
    </style>